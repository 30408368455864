<template>
  <v-container
    class="py-6 mx-auto my-10"
    style="max-width: 1500px; background: white !important"
  >
    <v-row>
      <v-col cols="4">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Зарлагдсан сорилууд (ЭЕШ800)
        </h5>
        <p class="text-body">
          ЭЕШ800 -гаас зардлагдсан сорилууд - Xаргалзаx шалгалтыг нээснээр
          сурагчид оролцоx боломжтой.
          <span v-if="filteredAssignmentsFinal">{{
            filteredAssignmentsFinal.length
          }}</span>
        </p>
      </v-col>
      <v-col lg="1" md="1" sm="6">
        <v-btn
          v-if="userData.role == 'superadmin'"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил+
        </v-btn>
      </v-col>
      <v-col lg="3" md="3" sm="12" cols="10" class="my-0 py-0">
        <v-select
          v-if="!$attrs.categoryRefPath"
          :items="lessonCategories"
          item-text="name3"
          item-value="id"
          return-object
          v-model="selectedFilterCategory"
          clearable
          label="Сорилын төрөл сонгоx"
        >
        </v-select>
      </v-col>
      <v-col lg="1" md="1" sm="12" cols="2" class="mt-0 py-0">
        <v-btn icon v-if="showInGrids" @click="showInGrids = !showInGrids"
          ><v-icon>mdi-view-grid-outline</v-icon></v-btn
        >
        <v-btn icon v-else @click="showInGrids = !showInGrids"
          ><v-icon>mdi-view-list-outline</v-icon></v-btn
        >
      </v-col>
      <!-- <v-col lg="3" md="3" sm="12" cols="12" class="my-0">
        <v-checkbox :value="true" :label="value?'kdfdjfkj':'aaa'" class="my-0 py-0"></v-checkbox>
      </v-col> -->
    </v-row>
    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids
      "
    >
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="(assignment, aindex) in filteredAssignmentsFinal"
        :key="assignment.id + 'assignments' + aindex"
      >
        <AssignmentCard
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
          :fromEYESH="isEyesh ? false : true"
        ></AssignmentCard>
      </v-col>
    </v-row>

    <v-row
      justify="start"
      v-if="
        filteredAssignmentsFinal &&
        filteredAssignmentsFinal.length > 0 &&
        showInGrids == false
      "
    >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Xайx"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        style="overflow-y: auto; width: 100%"
        class="elevation-1"
        :items="filteredAssignmentsFinal"
        :headers="headers"
        hide-details
        hide-default-footer
        :items-per-page="-1"
        :search="search"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <!-- <td v-if="props.item.categoryRef">{{ props.item.categoryRef.id }}</td>
            <td v-else>-</td>
            <td>{{ props.item.ref.path }}</td> -->
            <td>{{ props.item.index }}</td>
            <td style="width: 20%" @click="_detail(props.item)" class="hovertd">
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text class="text-capitalize">
                    {{ props.item.name }}
                  </v-btn>
                </template>
                <span>Дарсанаар шалгалтын материалыг xараx боломжтой</span>
              </v-tooltip>
            </td>

            <td>
              <span class="blue--text">{{ props.item.createdMonth }}</span
              >/{{ props.item.createdYear }}
            </td>
            <td v-if="props.item.selectedLessonCategory">
              {{ props.item.selectedLessonCategory.name }}
            </td>
            <td v-else>****</td>
            <td>
              <small class="blue--text">{{ props.item.description }}</small>
            </td>
            <td>
              <span class="blue--text" v-if="props.item.yeshLessonCode">{{
                props.item.yeshLessonCode
              }}</span>
              <span v-else>--</span>
            </td>

            <!-- <td>{{ props.item.ref.path }}</td>
            <td>
              <span v-if="props.item.categoryRef">{{
                props.item.categoryRef.path
              }}</span>
              <span v-else>-</span>
            </td> -->
            <td>{{ props.item.duration }}</td>
            <td>{{ props.item.finalScore }}</td>
            <td>
              <small
                v-if="props.item.slideUrl"
                :class="
                  _whereFrom(props.item.slideUrl) == 'web'
                    ? 'blue--text'
                    : 'amber--text'
                "
                >{{ _whereFrom(props.item.slideUrl) }}</small
              >
              <span v-else>-</span>
            </td>

            <td color="red">
              <small
                v-if="props.item.finished"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                elevation="0"
                :ripple="false"
                >Дууссан</small
              >
              <small v-if="props.item.public" small class="green--text"
                >Нээлттэй</small
              >
              <small v-else small class="red--text">Xаалттай</small>
            </td>
            <td v-if="props.item.public">
              <small
                @click="_makeAssignmentPublic(props.item)"
                v-if="props.item.isPublicForSchool"
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                elevation="0"
                :ripple="false"
                style="cursor: pointer"
              >
                Нээлттэй
              </small>
              <span
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                elevation="0"
                :ripple="false"
                @click="_makeAssignmentPublic(props.item)"
                style="cursor: pointer"
                v-else
              >
                <small>Xаалттай</small>
              </span>
            </td>
            <td v-else>Aдмин нээгээгүй</td>
            <td v-if="props.item.openForAllSchool" style="width: 10%">
              <small
                @click="_makeAssignmentPublic2(props.item)"
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                elevation="0"
                :ripple="false"
                style="cursor: pointer"
              >
                Нээлттэй-All
              </small>
            </td>
            <td v-else style="width: 10%">
              <span
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                elevation="0"
                :ripple="false"
                @click="_makeAssignmentPublic2(props.item)"
                style="cursor: pointer"
              >
                <small>Xаалттай-All</small>
              </span>
            </td>

            <td style="width: 10%">
              <span
                style="cursor: pointer"
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                @click.stop="
                  selectedAssignment = props.item;
                  openSorilResults = !openSorilResults;
                "
                >Үр дүн</span
              >
            </td>
            <!-- <td>
              <v-icon text @click="_selectPurchaseItem(props.item)">
                mdi-close
              </v-icon>
            </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-else-if="filteredAssignmentsFinal.length == 0" justify="center">
      <h5 class="text-center py-16 red--text">Одоогоор сорил байxгүй байна.</h5>
    </v-row>

    <v-card class="mt-16">
      <h1>Specials</h1>
      <v-row
        justify="start"
        v-if="
          filteredSpecialAssignmentsFinal &&
          filteredSpecialAssignmentsFinal.length > 0 &&
          showInGrids == false
        "
      >
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          style="overflow-y: auto; width: 100%"
          class="elevation-1"
          :items="filteredSpecialAssignmentsFinal"
          :headers="headers"
          hide-details
          hide-default-footer
          :items-per-page="-1"
          :search="search"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <!-- <td v-if="props.item.categoryRef">{{ props.item.categoryRef.id }}</td>
            <td v-else>-</td>
            <td>{{ props.item.ref.path }}</td> -->
              <td>{{ props.item.index }}</td>
              <td
                style="width: 20%"
                @click="_detail(props.item)"
                class="hovertd"
              >
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class="text-capitalize">
                      {{ props.item.name }}
                    </v-btn>
                  </template>
                  <span>Дарсанаар шалгалтын материалыг xараx боломжтой</span>
                </v-tooltip>
              </td>
              <td>
                <span class="blue--text">{{ props.item.createdMonth }}</span
                >/{{ props.item.createdYear }}
              </td>
              <td v-if="props.item.selectedLessonCategory">
                {{ props.item.selectedLessonCategory.name }}
              </td>
              <td v-else>****</td>
              <td>
                <small class="blue--text">{{ props.item.description }}</small>
              </td>
              <td>
                <span class="blue--text" v-if="props.item.yeshLessonCode">{{
                  props.item.yeshLessonCode
                }}</span>
                <span v-else>--</span>
              </td>

              <!-- <td>{{ props.item.ref.path }}</td>
            <td>
              <span v-if="props.item.categoryRef">{{
                props.item.categoryRef.path
              }}</span>
              <span v-else>-</span>
            </td> -->
              <td>{{ props.item.duration }}</td>
              <td>{{ props.item.finalScore }}</td>
              <td>
                <small
                  v-if="props.item.slideUrl"
                  :class="
                    _whereFrom(props.item.slideUrl) == 'web'
                      ? 'blue--text'
                      : 'amber--text'
                  "
                  >{{ _whereFrom(props.item.slideUrl) }}</small
                >
                <span v-else>-</span>
              </td>

              <td color="red">
                <small
                  v-if="props.item.finished"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                  elevation="0"
                  :ripple="false"
                  >Дууссан</small
                >
                <small v-if="props.item.public" small class="green--text"
                  >Нээлттэй</small
                >
                <small v-else small class="red--text">Xаалттай</small>
              </td>
              <td v-if="props.item.public">
                <small
                  @click="_makeAssignmentPublic(props.item)"
                  v-if="props.item.isPublicForSchool"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                  elevation="0"
                  :ripple="false"
                  style="cursor: pointer"
                >
                  Нээлттэй
                </small>
                <span
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                  elevation="0"
                  :ripple="false"
                  @click="_makeAssignmentPublic(props.item)"
                  style="cursor: pointer"
                  v-else
                >
                  <small>Xаалттай</small>
                </span>
              </td>
              <td v-else>Aдмин нээгээгүй</td>
              <td v-if="props.item.openForAllSchool" style="width: 10%">
                <small
                  @click="_makeAssignmentPublic2(props.item)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0"
                  :ripple="false"
                  style="cursor: pointer"
                >
                  Нээлттэй-All
                </small>
              </td>
              <td v-else style="width: 10%">
                <span
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                  elevation="0"
                  :ripple="false"
                  @click="_makeAssignmentPublic2(props.item)"
                  style="cursor: pointer"
                >
                  <small>Xаалттай-All</small>
                </span>
              </td>

              <td style="width: 10%">
                <span
                  style="cursor: pointer"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                  @click.stop="
                    selectedAssignment = props.item;
                    openSorilResults = !openSorilResults;
                  "
                  >Үр дүн</span
                >
              </td>
              <!-- <td>
              <v-icon text @click="_selectPurchaseItem(props.item)">
                mdi-close
              </v-icon>
            </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-card>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ сорил/шалгалт үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-radio-group
                v-model="editedItem.assignmentType"
                :rules="[rules.required]"
              >
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилал сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  height="100"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveInYeshDatabase"
          >
            Xадгалаxx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-if="selectedAssignment && openSorilResults"
      v-model="openSorilResults"
      style="background: white !important"
    >
      <v-card style="margin-top: -30px !important; height: 110%">
        <v-btn
          @click="openSorilResults = !openSorilResults"
          class="bg-gradient-danger white--text mt-12 ml-4 mb-n8"
          >Буцах
        </v-btn>
        <v-card-text>
          {{ selectedAssignment.ref.path }},
          {{ selectedAssignment._originalAssignmentRefPath }}
        </v-card-text>
        <div v-if="selectedAssignment.assignmentType == 1">
          <XShalgaltResults :item="selectedAssignment"></XShalgaltResults>
        </div>
        <div v-else-if="selectedAssignment._notOrginal">
          <XShowAssignmentAllResults
            :sorilId="selectedAssignment._originalAssignmentRefPath"
            :maxNumber="selectedAssignment.yyy"
            :fromEYESH="isEyesh ? false : true"
          ></XShowAssignmentAllResults>
        </div>
        <div v-else>
          <XShowAssignmentAllResults
            :sorilId="selectedAssignment.ref.path"
            :maxNumber="selectedAssignment.yyy"
            :fromEYESH="isEyesh ? false : true"
          ></XShowAssignmentAllResults>
        </div>
      </v-card>
    </v-dialog> -->

    <v-dialog
      v-if="selectedAssignment && openSorilResults"
      v-model="openSorilResults"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="6" md="6" lg="6"> </v-col>
            <v-col cols="6" md="6" lg="6" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="openSorilResults = !openSorilResults"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="selectedAssignment.assignmentType == 1">
            <XShalgaltResults :item="selectedAssignment"></XShalgaltResults>
          </div>

          <div>
            <XShowAssignmentAllResults
              :sorilId="selectedAssignment.ref.path"
              :maxNumber="selectedAssignment.yyy"
              :fromEYESH="isEyesh ? false : true"
            ></XShowAssignmentAllResults>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="openSorilResults = !openSorilResults"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

import AssignmentCard from "./AssignmentCard.vue";
import XShowAssignmentAllResults from "./XShowAssignmentAllResults";
export default {
  components: { AssignmentCard, XShowAssignmentAllResults },
  data: () => ({
    search: "",
    selectedAssignment: null,
    openSorilResults: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },

      {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "selectedLessonCategory.name",
      },

      {
        text: "description",
        align: "start",
        sortable: true,
        value: "description",
      },
      {
        text: "Code",
        align: "start",
        sortable: true,
        value: "yeshLessonCode",
      },

      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    showInGrids: true,
    filteredAssignments: null,
    filteredSpecialAssignments: null,
    selectedFilterCategory: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories1: null,
    lessonCategories2: null,
  }),
  props: {
    allAssignments: {
      type: Boolean,
      default: true,
    },
    categoryRefPath: {
      type: String,
    },
  },
  computed: {
    lessonCategories() {
      var list = [];
      if (this.lessonCategories1)
        this.lessonCategories1.forEach((assignment) => {
          list.push(assignment);
        });
      if (this.lessonCategories2)
        this.lessonCategories2.forEach((assignment) => {
          list.push(assignment);
        });
      return list;
    },
    isEyesh() {
      return fb.EYESH_APP;
    },
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
    },
    filteredAssignmentsFinal() {
      var list = [];
      if (this.filteredAssignments) {
        var counter = 0;
        this.filteredAssignments.forEach((element) => {
          counter++;
          element.index = counter;
          list.push(element);
        });
      }
      return list;
    },
    filteredSpecialAssignmentsFinal() {
      var list = [];
      if (this.filteredSpecialAssignments) {
        var counter = 0;
        this.filteredSpecialAssignments.forEach((element) => {
          counter++;
          element.index = counter;
          list.push(element);
        });
      }
      return list;
    },
  },
  created() {
    if (
      this.userData["_user-ui-settings"] &&
      this.userData["_user-ui-settings"]["eyesh800-view-grid"]
    ) {
      this.showInGrids =
        this.userData["_user-ui-settings"]["eyesh800-view-grid"];
    } else this.showInGrids = false;
    this._setupp();
  },
  watch: {
    showInGrids(val) {
      if (!this.userData["_user-ui-settings"]) {
        this.userData["_user-ui-settings"] = { "eyesh800-view-grid": val };
      } else this.userData["_user-ui-settings"]["eyesh800-view-grid"] = val;
      this.userData.ref
        .set(
          { "_user-ui-settings": this.userData["_user-ui-settings"] },
          { merge: true }
        )
        .then(() => {});
    },
    selectedFilterCategory(val) {
      if (val && this.assignments) {
        var counter1 = 0;
        this.filteredAssignments = [];
        this.assignments.forEach((x) => {
          if (x.categoryRef && x.categoryRef.id == val.id) {
            counter1++;
            x.index = counter1;
            this.filteredAssignments.push(x);
          }
        });
      } else {
        var counter2 = 0;
        this.filteredAssignments = [];
        this.assignments.forEach((x) => {
          counter2++;
          x.index = counter2;
          this.filteredAssignments.push(x);
        });
      }
    },
  },
  methods: {
    isEYESH() {
      return fb.EYESH_APP;
    },
    _whereFrom(str) {
      if (str.toLowerCase().indexOf("drive") > -1) return "drive";
      else if (str.toLowerCase().indexOf("iframe") > -1) return "web";
      else return null;
    },
    _detail(item) {
      this.selectedAssignment = item;
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditing",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        console.log(item.ref.path);
        this.$router.push({
          name: "XShalgaltEditingEyesh",
          params: {
            xpath: item.ref.path,
            fromEYESH: true,
          },
        });
        // if (this.userData && item.createdByEmail == this.userData.email) {
        //   this.$router.push({
        //       name: "XShalgaltEditingEyesh",
        //       params: {
        //         xpath: item.ref.path,
        //       },
        //     });
        // } else {
        //   this.$router.push({
        //     name: "XShalgaltEyeshViewDialog",
        //     params: {
        //       xpath: item.ref.path,
        //     },
        //   });
        // }
      }
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear().toString().slice(-2);
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      if (day.length === 1) {
        day = "0" + day;
      }

      return `${year}${month}${day}`;
    },
    async _makeAssignmentPublic2(assignment) {
      console.log(assignment, assignment.createdAt.toDate(), fb.EYESH_APP);
      assignment["origName"] = assignment.name;
      var xx = await fb.dbeyesh800.doc(assignment.categoryRefPath).get();
      if (xx.exists) {
        assignment.yeshLessonCode = xx.data().yeshLessonCode
          ? xx.data().yeshLessonCode
          : null;
        assignment.name = xx.data().yeshLessonCode
          ? xx.data().name.replace("ЭЕШ-", "") +
            "-" +
            this.getCurrentDate(new Date())
          : null;
      }
      console.log(assignment.yeshLessonCode, assignment.name);
      assignment.categoryRef = null;
      assignment.createdByRef = null;
      assignment.selectedLessonCategory = null;
      assignment.deleted = false;
      assignment.publishedAt = new Date();
      if (!assignment.cycleIndex) {
        assignment.cycleIndex = 5;
      }
      assignment.openForAllSchool = false;
      if (assignment.openForAllSchool == false) {
        fb.dbeyesh800
          .collection("_public-assignments-allschools")
          .doc(assignment.id)
          .set(assignment, { merge: true })
          .then(async () => {
            console.log("_public-assignments-allschools/" + assignment.id);
            var batch = fb.dbeyesh800.batch();
            var oldCats = await assignment.ref.collection("categories").get();

            if (oldCats.empty == false) {
              oldCats.docs.forEach(async (category) => {
                let categoryData = category.data();
                categoryData.ref = null;
                categoryData.questions = null;
                batch.set(
                  fb.dbeyesh800
                    .collection("_public-assignments-allschools")
                    .doc(assignment.id)
                    .collection("categories")
                    .doc(category.id),
                  categoryData
                );
                var oldquestions = await fb.dbeyesh800
                  .doc(category.ref.path)
                  .collection("questions")
                  .get();
                if (oldquestions.empty == false) {
                  for (const question of oldquestions.docs) {
                    let questionData = question.data();
                    questionData.ref = null;
                    batch.set(
                      fb.dbeyesh800
                        .collection("_public-assignments-allschools")
                        .doc(assignment.id)
                        .collection("categories")
                        .doc(category.id)
                        .collection("questions")
                        .doc(questionData.id),
                      questionData
                    );
                  }
                }
              });
            }
            setTimeout(() => {
              batch.commit().then(() => {
                assignment.openForAllSchool = !assignment.openForAllSchool;
                this.$forceUpdate();
                console.log("completed");
              });
            }, 2000);
          });
      } else {
        // assignment.ref.update({ deleted: true }).then(() => {
        //   assignment.openForAllSchool = !assignment.openForAllSchool;
        //   this.$forceUpdate();
        // });
        console.log("No ...");
        assignment.openForAllSchool = !assignment.openForAllSchool;
        this.$forceUpdate();
      }
    },
    _makeAssignmentPublic(assignment) {
      console.log(assignment);
      // if (!assignment.isPublicForSchool) {
      //   assignment.isPublicForSchool = true;
      // } else assignment.isPublicForSchool = !assignment.isPublicForSchool;
      //TODOXangal
      if (fb.EYESH_APP == false) {
        assignment.categoryRef = null;
        assignment.createdByRef = null;
        assignment.selectedLessonCategory = null;
        assignment.ref = null;
        assignment._originalAssignmentRef = null;
      }
      if (assignment.isPublicForSchool == false && this.userData.school) {
        this.userData.school.ref
          .collection("opened-assignments")
          .doc(assignment.id)
          .set(assignment, { merge: true })
          .then(() => {
            assignment.isPublicForSchool = true;
          });
      } else {
        this.userData.school.ref
          .collection("opened-assignments")
          .doc(assignment.id)
          .delete()
          .then(() => {
            assignment.isPublicForSchool = false;
          });
      }
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveInYeshDatabase() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.assignmentType &&
        this.editedItem.selectedLessonCategory
      ) {
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;
        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2 = await fb.dbeyesh800.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }

          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          // this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          // this.schoolIndex = this.userData.schoolIndex
          //   ? this.userData.schoolIndex
          //   : null;

          console.log(this.editedItem)
          // fb.dbeyesh800
          //   .collection("_public-assignments-allschools")
          //   .add(this.editedItem)
          //   .then((docRef) => {
          //     console.log("Document xxxxx written!", docRef);
          //   })
          //   .catch((error) => {
          //     console.error("Error writing document: ", error);
          //   });
        } else {
          console.log(this.editedItem.categoryRef);
          // this.editedItem.modifiedAt = new Date();
          // this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      }
    },
    async saveItem() {
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.assignmentType &&
        this.editedItem.selectedLessonCategory
      ) {
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;
        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2;
            if (fb.EYESH_APP) {
              x2 = await fb.db.doc(this.zzschool).get();
            } else {
              x2 = await fb.dbeyesh800.doc(this.zzschool).get();
            }
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }

          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          // this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          this.schoolIndex = this.userData.schoolIndex
            ? this.userData.schoolIndex
            : null;
          if (this.EYESH_APP) {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.dbeyesh800
              .collection("_public-assignments-allschools")
              .add(this.editedItem)
              .then((docRef) => {
                console.log("Document xxxxx written!", docRef);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _findx(ccc) {
      console.log(ccc, this.lessonCategories.length);
      var number = 0;
      if (this.lessonCategories) {
        for (var lcategory of this.lessonCategories) {
          if (lcategory.ref.path == ccc) {
            number = lcategory.counter;
            break;
          }
        }
      }
      return number;
    },
    async findAssignment() {
      var query;
      if (fb.EYESH_APP) {
        query = fb.db
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false);
      } else {
        query = fb.dbeyesh800
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false);
      }
      query.orderBy("createdAt", "desc").onSnapshot((docs) => {
        this.assignments = [];
        this.filteredAssignments = [];
        docs.forEach((doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;
          if (!assign.yeshLessonCode) assign.yeshLessonCode = "N/A";
          if (this.allAssignments) {
            if (
              assign.createdByEmail == "granditxxk@gmail.com" &&
              this.userData.school
            ) {
              this.userData.school.ref
                .collection("opened-assignments")
                .doc(assign.id)
                .get()
                .then(async (doc) => {
                  if (doc.exists) {
                    assign.isPublicForSchool = true;
                    assign._notOrginal = true;
                    assign._originalAssignmentRef = assign.ref;
                    assign._originalAssignmentRefPath = assign.ref.path;
                    assign.categoryRef = assign.selectedLessonCategory
                      ? assign.selectedLessonCategory.ref
                      : null;
                    assign.categoryRefPath = assign.selectedLessonCategory
                      ? assign.selectedLessonCategory.ref.path
                      : null;
                  } else {
                    assign.isPublicForSchool = false;
                    assign._notOrginal = true;
                    assign._originalAssignmentRef = assign.ref;
                    assign._originalAssignmentRefPath = assign.ref.path;
                    assign.categoryRef = assign.selectedLessonCategory
                      ? assign.selectedLessonCategory.ref
                      : null;
                    assign.categoryRefPath = assign.selectedLessonCategory
                      ? assign.selectedLessonCategory.ref.path
                      : null;
                  }
                  if (!assign.yeshLessonCode) {
                    var lessonCategory =
                      await assign.selectedLessonCategory.ref.get();
                    if (lessonCategory.exists) {
                      console.log(lessonCategory.data().yeshLessonCode);
                      assign.ref.update({
                        yeshLessonCode: lessonCategory.data().yeshLessonCode,
                      });
                    }
                  }

                  this.assignments.push(assign);
                  this.filteredAssignments.push(assign);
                });
            }
          } else {
            if (
              this.$attrs.categoryRefPath &&
              assign.categoryRef &&
              assign.categoryRef.path == this.$attrs.categoryRefPath
            ) {
              if (
                this.userData.schoolIndex &&
                assign.schoolIndex == this.userData.schoolIndex
              ) {
                this.filteredAssignments.push(assign);
                console.log(assign.schoolIndex);
              }
              if (this.userData.school) {
                if (
                  assign.createdBySchoolRef &&
                  this.userData.school.ref.path ==
                    assign.createdBySchoolRef.path
                ) {
                  this.assignments.push(assign);
                  this.filteredAssignments.push(assign);
                }
              } else {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }

              if (assign.createdByEmail == this.userData.email) {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }
              if (assign.createdByEmail == "granditxxk@gmail.com") {
                this.assignments.push(assign);
                this.filteredAssignments.push(assign);
              }
            }
          }
        });
      });
      if (this.userData.school) {
        await this.userData.school.ref
          .collection("assignmentsdatabase")
          .where("deleted", "==", false)
          .orderBy("createdAt", "desc")
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.ref = doc.ref;
              assign.id = doc.id;
              if (
                this.$attrs.categoryRefPath &&
                assign.categoryRef.path == this.$attrs.categoryRefPath
              ) {
                // this.assignments.push(assign);
                // this.filteredAssignments.push(assign);
              }
            });
          });
      }
    },
    async findSpecialAssignment() {
      var query = fb.dbeyesh800
        .collectionGroup(this.assignmentsDatabasePath)
        .where("examScope.sorilIndex", "==", 1)
        // .where("schoolIndex", "==", this.userData.schoolIndex)
        .where("deleted", "==", false);

      query.orderBy("createdAt", "desc").onSnapshot((docs) => {
        this.filteredSpecialAssignments = [];
        docs.forEach(async (doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;

          fb.dbeyesh800
            .collection("_public-assignments-allschools")
            .doc(assign.id)
            .get()
            .then(async (doc) => {
              if (doc.exists) {
                assign.isPublicForSchool = true;
                assign.openForAllSchool = true;
              } else {
                assign.isPublicForSchool = false;
                assign.openForAllSchool = false;
              }
              assign.categoryRef = assign.selectedLessonCategory.ref;
              assign.categoryRefPath = assign.selectedLessonCategory.ref.path;
            });

          this.filteredSpecialAssignments.push(assign);
        });
      });
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.db
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories1 = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories1.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  this.lessonCategories2 = [];
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories2.push(item);
                  });
                  // this.findAssignment();
                });
            }
            this.findAssignment();
          });
      } else {
        fb.dbeyesh800
          .collection("categories")
          .orderBy("name", "asc")
          .onSnapshot((docs) => {
            this.lessonCategories1 = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item.name2 = item.name + " - " + item.lessonTypeName;
              item.name3 =
                counter + ". " + item.name + " - " + item.lessonTypeName;
              this.lessonCategories1.push(item);
            });
            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  this.lessonCategories2 = [];
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 = item.name + " - " + item.lessonTypeName;
                    this.lessonCategories2.push(item);
                  });
                  // this.findAssignment();
                });
            }
            this.findAssignment();
            this.findSpecialAssignment();
          });
      }
    },
  },
};
</script>
<style>
.cbadge {
  position: absolute;
  top: -10px;
  left: -30px;
  padding: 5px;
  background: blue;
  color: white;
  transform: rotate(-20deg);
}
.hovertd:hover {
  color: red;
  cursor: pointer;
}
</style>
