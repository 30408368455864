<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px" v-if="item">
      <v-card class="mx-auto" style="max-width: 1000px">
        <v-card-title>
          <h3 class="mb-10 indigo--text">
            <span class="font-weight-bold">Шалгалтын үр дүн:</span>
            {{ item.title }}
          </h3>
        </v-card-title>
        <v-card-title>
          <span class="font-weight-bold" v-if="questions != null"
            >Aсуултын тоо: {{ questions.length }}
          </span>

          <span class="font-weight-bold" v-if="users != null"
            >, оролцогчдын тоо: {{ users.length }}</span
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          multi-sort
          :headers="headers2"
          :items="users2"
          :search="search"
          :items-per-page="-1"
          ><template v-slot:item.sn="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.avatar="{ item }">
            <v-avatar color="indigo" dark size="32"
              ><span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span></v-avatar
            >
          </template></v-data-table
        >
      </v-card>
    </v-container>
    <v-dialog v-model="showDetail" max-width="40%" v-if="selectedItem">
      <!-- xTODO set button under scroll-->
      <v-card height="100%">
        <v-row no-gutters scrollable>
          <v-col md="6" offset-md="3">
            <div
              v-for="(cat, catindex) in selectedItem.asuulga.categories"
              v-bind:key="catindex"
            >
              <br />
              <v-btn
                elevation="0"
                class="py-4 font-weight-bold my-0"
                color="primary"
              >
                БҮЛЭГ. {{ cat.name }}</v-btn
              >
              <div>
                <v-card
                  class="pa-2 mt-0 mb-2"
                  outlined
                  tile
                  v-for="(question, qindex) in cat.questions"
                  :key="qindex"
                >
                  <h4>{{ qindex + 1 }}. {{ question.name }}</h4>
                  <v-list>
                    <v-list-item
                      class="px-1"
                      v-for="answer in question.answers"
                      :key="answer.id"
                    >
                      <v-list-item-avatar class="mr-0">
                        <v-icon
                          v-if="answer.answered == true"
                          style="color: red"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else>
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap"
                          v-text="answer.name"
                        />
                      </v-list-item-content>

                      <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-spacer />
          <v-btn color="green darken-1" text @click="showDetail = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");
//
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [myfunction],
  data: () => ({
    isAnonymous: true,
    search: "",
    users: null,
    showDetail: false,
    selectedItem: null,
    selectedIndex: -1,
    prePath: null,
    filterMinLimit: 0,
    categories: null,
    questions: null,

    students: null,
  }),
  watch: {
    filterMinLimit: function (val) {
      console.log(val, this.filterMinLimit);
      // for (var uuser of this.users) {
      //   filteredUsers.push();
      // }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    headers2() {
      var list = [];
      if (this.questions != null) {
        list.push({ text: "No", value: "sn", width: "1%" });
        list.push({
          text: "",
          value: "avatar",
        });
        list.push({
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
        });

        if (!this.isAnonymous) {
          list.push({
            text: "lastName",
            align: "start",
            sortable: true,
            value: "lastName",
          });
        }

        // var counter = 1;
        // for (var item of this.questions) {
        //   list.push({ text: "A-" + counter, value: "A-" + counter });
        //   counter++;
        // }
        // list.push({ text: "x", value: "x", sortable: true });
        list.push({ text: "Нийт оноо", value: "totalScore", sortable: true });
      }
      return list;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          var xx = {
            name: item.answerer.firstName,
            lastName: item.answerer.lastName,
            totalScore: item.totalScore,
          };
          var counter = 1;
          for (var answer of item.answers) {
            xx["A-" + counter] = answer.questionScored;
            counter++;
          }
          list.push(xx);
        }
      }
      return list;
    },
  },
  created() {
    this._getResults();
    this._findCategories();
  },
  methods: {
    _clickCell(uquestion) {
      console.log(uquestion);
    },
    getitemcontrols(cat) {
      return "item." + cat.name;
    },
    _detail(item, index) {
      this.showDetail = true;
      this.selectedItem = item;
      this.selectedIndex = index;
    },
    _getDate(user) {
      if (user.asuulga != null && user.asuulga.sentAt)
        return user.asuulga.sentAt.toDate();
    },

    _getCategoryScore(asuulga) {
      var scores = [];
      for (var cat of asuulga.categories) {
        scores.push(cat.scored);
      }
      return scores;
    },
    _getCategoryScoreOfUser(user) {
      if (user.asuulga) {
        for (var asuu of user.asuulga) {
          if (asuu.asuulgaId == this.asuulgaId) {
            return this._getCategoryScore(asuu);
            //return -2;
          }
        }
      } else return -3;

      return -1;
    },
    _getCategories(user) {
      if (user.asuulga) {
        for (var asuu of user.asuulga) {
          console.log(asuu.categories);
          if (asuu.asuulgaId == this.asuulgaId) {
            return [1, 2];
          }
        }
      } else return [];

      return [];
    },
    async _findCategories() {
      fb.db
        .doc(this.item.ref.path)
        .collection("/categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            // this.headers.push({
            //   text: "БҮЛЭГ-" + cat.name,
            //   value: cat.name,
            //   sortable: true,
            // });
          });
        });
    },

    _getQuestionsOfCategory(cat) {
      fb.db
        .collection(this.item.ref.path + "/categories/" + cat.id + "/questions")
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          this.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            question.id = doc.id;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            question.answers = [];
            doc.ref
              .collection("answers")
              // .orderBy("score", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;
                  answer.answered = false;
                  question.answers.push(answer);
                });
              });

            cat.questions.push(question);
            this.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },

    async _getResults() {
      fb.db
        .doc(this.item.ref.path)
        .collection("/results")
        .orderBy("answeredAt", "asc")
        .onSnapshot((querySnapshot) => {
          this.users = [];
          querySnapshot.forEach((doc) => {
            const student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            this._getUserAnswers(student);
            this.users.push(student);
          });
        });
    },
    _getUserAnswers(student) {
      student.answers = [];
      for (var cat of student.categories) {
        for (var question of cat.questions) {
          student.answers.push(question);
        }
      }
    },
    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
